import React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from './index.module.scss';

const TeamCard = ({
    image,
    name,
    job,
    email,
    contact,
    region
}) => (
    <div className={styles.TeamCard}>
        <div className={styles.TeamCardImage}>
            {(typeof image !== 'undefined') ? <GatsbyImage image={getImage(image)} alt={image?.name} /> : null}
        </div>
        <div className={styles.TeamCardWrap}>
            {(name && name.length > 0) ? <h4>{name}</h4> : null}
            {(job && job.length > 0) ? <p>{job}</p> : null}
            {(region && region.length > 0) ? <p className={styles.region}>{region}</p> : null}
            <div className={styles.TeamCardWrapLine}>
                {(email && email.length > 0) ? <p><a href={`mailto:${email}`}>{email}</a></p> : null}
                {(contact && contact.length > 0) ? <p><a href={`tel:+${contact}`}>{contact}</a></p> : null}
            </div>
        </div>
    </div>
)

export default TeamCard;