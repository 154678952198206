import React from 'react';
import { BannerCardContainer } from '../banner-card-container';

import * as styles from './index.module.scss';

/**
  Export the banner cards
 * @param object data
 * @returns 
 */
export const BannerCardType = (props) => {
  let { 
    backgroundColour,
    backgroundImage,
    template,
    textColourSelect,
    buttonUrl,
    openInANewTab,
    buttonFile,
    style,
    padding
  } = props;

  if (buttonFile && buttonFile.publicUrl)
    buttonUrl = buttonFile.publicUrl;

  let cardClass = (template === 'Product') ? `${styles.card}` : `${styles.card}`;
  let colourClass = (textColourSelect !== false) ? `${styles.whiteColour}` : ``;
  let accordionClass = (template === 'Accordion') ? `${styles.accordionNoPadding}` : ``;

  let isAccordionTemplate = template === 'Accordion';
  let defaultAccordionColor = '#f5f5f5'; 

  if (style === 'tall')
    cardClass += ' ' + styles.tall;

  padding = padding?.replace(' ', '');

  cardClass += ' ' + (padding ? styles[padding?.toLowerCase()] : styles['small']);

  if ([
    'Image Only'
  ].includes(template))
    return (
      <div
        className={`${cardClass} ${colourClass}`}
      >
        <img src={backgroundImage.url} alt={backgroundImage.alt} />
      </div>
    );

  if ([
    'Trustpilot',
    'Accordion',
    'Form',
    'Product Description',
    'Product Features',
    'Footer Form'
  ].includes(template))
    return (
      <div
        className={`${cardClass} ${colourClass} ${accordionClass}`}
        style={{
          backgroundImage: ((backgroundImage && typeof backgroundImage === 'object' && backgroundImage.url.length > 0) ? `url(${backgroundImage.url})` : '')
        }}
      >
        <div
          className={styles.overlay}
          style={{
            backgroundColor: (isAccordionTemplate && (!backgroundColour || backgroundColour.length > 0)) ? defaultAccordionColor : backgroundColour
          }}
        ></div>
        <BannerCardContainer {...props} />
      </div>
    );

    if ([
      'Image Vertical Top'
    ].includes(template))
      return (
        <div
          className={`${cardClass} ${colourClass}` + styles.ImageVerticalTop}
        >
          <img src={backgroundImage.url} alt={backgroundImage.alt} />
        </div>
      );

  return (
    <a
      href={buttonUrl}
      className={`${cardClass} ${colourClass}`}
      style={{
        backgroundImage: ((backgroundImage && typeof backgroundImage === 'object' && backgroundImage.url.length > 0) ? `url(${backgroundImage.url})` : '')
      }}
      target={openInANewTab ? '_blank' : '_self'}>
      <div
        className={styles.overlay}
        style={{
          backgroundColor: (backgroundColour && backgroundColour.length > 0) ? backgroundColour : ''
        }}
      ></div>
      <BannerCardContainer {...props} />
    </a>
  );
}