import React from 'react';
import { BannerCardContentLeft } from './lib/banner-card-content-left/index';
import { BannerCardContentRight } from './lib/banner-card-content-right/index';

import * as styles from './index.module.scss';

// Create a utility class function, like getTemplateClasses
// In the function, have some arguments for props.template === 'product' || props.sideImage and also pass the sideImageLocation prop
// Add classes depending on that

const getSideImageLocationClass = (sideImageLocation) => {
  let classes = '';
  switch (sideImageLocation) {
    case 'Center':
      classes += " " + 'side-image-center-col';
      break;
    case 'Right':
      classes += " " + 'right-col';
      break;
    default: // Left as default
      classes += " " + 'side-image-left-col';
      break;
  }
  return classes;
}

const getShowDotClass = (showDot) => {
  let classes = '';
  switch (showDot) {
    case 'No':
      classes += " " + styles.hideDot;
      break;
    default: // Yes as default
      classes += " " + styles.showDot;
  }
  return classes;
}

export const BannerCardContent = (props) => (
  <>
    <div id={`banner-block-left-col-${props?.id}`} className={styles.col + ' left-col' + getShowDotClass(props.showDot)}>
      <BannerCardContentLeft {...props} />
    </div>
    { (props.template === 'product' || props.sideImage) ? (
      <div className={styles.col + getSideImageLocationClass(props.sideImageLocation)}>
        <BannerCardContentRight {...props} />
      </div> 
    ) : null }
  </>
);