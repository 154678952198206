import React from 'react';

import * as styles from './index.module.scss';
import TickIcon from './../../lib/fontawesome/TickIcon';

const FeatureList = ({
    features,
    collapsed = false
}) => {
    if (!features || typeof features === 'undefined' || features.length === 0)
        return;

    if (collapsed)
        features = features.slice(0, 4);

    return (
        <ul className={styles.featureList}>
            {features.map((feature, index) => <li key={feature}><TickIcon fill='#95C11F' className={styles.icon} />{feature}</li>)}
        </ul>
    );
}

export default FeatureList;