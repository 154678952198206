import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { HeaderSections } from './lib/header-sections';

import * as styles from './index.module.scss';

export const Header = () => {
  return (
    <header className={styles.header}>
      <StaticQuery
        query={graphql`
          query { 
            contentfulMenus {
              menuItems {
                id
                template
                openInANewTab
                title
                description
                url
                styleAsAButton
                image {
                  gatsbyImageData(
                    width: 150
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
                featuredBlocks {
                  ...BannerCardFields
                }
                subMenu {
                  title
                  description
                  id
                  openInANewTab
                  url
                  styleAsAButton
                  image {
                    gatsbyImageData(
                      width: 150
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                  subMenu {
                      title
                      description
                      id
                      openInANewTab
                      url
                      styleAsAButton
                      image {
                        gatsbyImageData(
                          width: 150
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                        )
                      }
                  }
                }
              }
            }
          }        
        `}
        render={data => <HeaderSections data={data} />}
      />
    </header>
  )
}