import React, { useEffect, useState } from "react";

import { Header } from "../header";
import { Footer } from "../footer";
import LiveChat from "../live-chat";
import MinimalHeader from "../header/minimal";
import CallToActionBanner from "../cta-banner";

import * as styles from './index.module.scss';

export const Layout = ({ children, minimal = false, haveLayout = true }) => {
  // A function to allow CSS env for keyboard height
  useEffect(() => {
    if ("virtualKeyboard" in navigator)
      navigator.virtualKeyboard.overlaysContent = true;
  });
  if (haveLayout)
    return (
      <div>
        {!minimal && <Header />}
        {minimal && <MinimalHeader />}
        {!minimal && <CallToActionBanner />}
        {!minimal && <HeaderTrustBox />}
        <main className={`${styles.container}`}>
          { children }
        </main>
        <Footer minimal={minimal} />
        {!minimal && <LiveChat />}
      </div>
    );
  else
      return (
        <main className={`${styles.container}`}>
          { children }
        </main>
      );
};

const HeaderTrustBox = () => {
    
  const [element, setElement] = useState(null);

  useEffect(() => {
      if (window && typeof window !== "undefined" && window.Trustpilot && element !== null)
      window.Trustpilot.loadFromElement(element, true);
  }, [element]);

  const refCallback = (node) => {
      if (node !== null)
      setElement(node);
  };
  
  return (
    <aside className={styles.headerTrustpilot}>
      <div
        ref={refCallback}
        class="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="5406e65db0d04a09e042d5fc"
        data-businessunit-id="5fcf6217a7bc150001d0044e"
        data-style-height="28px" data-style-width="100%" data-theme="light"> 
        <a href="https://uk.trustpilot.com/review/idealheating.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
      </div>
    </aside>
  );
}