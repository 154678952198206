// extracted by mini-css-extract-plugin
export var ImageVerticalTop = "index-module--ImageVerticalTop--08832";
export var accordionNoPadding = "index-module--accordionNoPadding--527ae";
export var card = "index-module--card--68b3f";
export var extralarge = "index-module--extralarge--acee3";
export var extrasmall = "index-module--extrasmall--9a37e";
export var large = "index-module--large--52f2f";
export var medium = "index-module--medium--a8ca5";
export var nopadding = "index-module--nopadding--51de3";
export var overlay = "index-module--overlay--4fe49";
export var small = "index-module--small--9fb90";
export var tall = "index-module--tall--80b6e";
export var trustpilot = "index-module--trustpilot--d6216";
export var whiteColour = "index-module--whiteColour--a8764";