import React, {useState, useEffect} from 'react';
import { StaticQuery, graphql } from 'gatsby';
import * as styles from './index.module.scss'

import { FooterSections } from './lib/footer-sections';
import MinimalFooter from './minimal';

export const Footer = ({ minimal = false }) => {
  return(
    <>
      <FooterTrustBox />
      <footer className={styles.footer}>
        <StaticQuery
          query={graphql`
            query {
              contentfulFooter {
                footerSections {
                  references {
                    section
                    sectionTitle {
                      raw
                    }
                    footerLinks {
                      menuItemName
                      menuItemText
                      url
                      image {
                        url
                      }
                    }
                  }
                }
                footerSocials {
                  references {
                    socialMedia {
                      references {
                        menuItemName
                        menuItemText
                        url
                        image {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }            
          `}
          render={data => minimal ? <MinimalFooter data={data} /> : <FooterSections data={data} />}
        />
      </footer>
    </>
  )
}

const FooterTrustBox = () => {
    
  const [element, setElement] = useState(null);

  useEffect(() => {
      if (window && typeof window !== "undefined" && window.Trustpilot && element !== null)
      window.Trustpilot.loadFromElement(element, true);
  }, [element]);

  const refCallback = (node) => {
      if (node !== null)
      setElement(node);
  };

  return (
    <aside className={styles.footerTrustpilot}>
      <div ref={refCallback} class="trustpilot-widget" data-locale="en-GB" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="5fcf6217a7bc150001d0044e" data-style-height="24px" data-style-width="100%" data-theme="dark">
        <a href="https://uk.trustpilot.com/review/idealheating.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
      </div>
    </aside>
  );
}