import React, { useEffect, useState } from 'react';
import * as styles from './index.module.scss';

export const TrustBox = ({ full }) => {
  const [element, setElement] = useState(null);

  useEffect(() => {
    if (window && typeof window !== "undefined" && window.Trustpilot && element !== null)
      window.Trustpilot.loadFromElement(element, true);
  }, [element]);

  const refCallback = (node) => {
    if (node !== null)
      setElement(node);
  };

  if (full) {
    return (
      <div 
        ref={refCallback}
        className={`trustpilot-widget ${styles.trust}`}
        data-locale="en-GB"
        data-template-id="54ad5defc6454f065c28af8b"
        data-businessunit-id="5fcf6217a7bc150001d0044e"
        data-style-height="240px"
        data-style-width="100%"
        data-theme="light"
        data-stars="4,5"
        data-review-languages="en">
        <a href="https://uk.trustpilot.com/review/idealheating.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
      </div>
    );
  }

  return (
    <div
      ref={refCallback}
      className={`trustpilot-widget ${styles.trust}`}
      data-locale="en-GB"
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id="5fcf6217a7bc150001d0044e"
      data-style-height="140px"
      data-style-width="100%"
      data-theme="light"
      data-stars="4,5"
      data-review-languages="en"
    >
      <a href="https://uk.trustpilot.com/review/idealheating.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
    </div>
  );
};

export default TrustBox;