import React from 'react';
import { BannerCardContent } from '../banner-card-content';

import * as styles from './index.module.scss';

const getTemplateClasses = (template) => {
  let classes = '';
  switch (template) {
    case "Content Vertical Top":
      classes += " " + styles.VerticalTop;
      break;
    case "Content Vertical Center":
      classes += " " + styles.VerticalCenter;
      break;
    case "Content Vertical Bottom":
      classes += " " + styles.VerticalBottom;
      break;
    case "Content Vertical Stretched":
      classes += " " + styles.VerticalStretch;
      break;
    case "Product Features":
      break;
    case "Content Stacked":
      classes += " " + styles.ContentStacked;
      break;
    case "Content Stacked - Image Bottom, full bleed":
      classes += " " + styles.ContentStackedImageFullBleed;
      break;
    case "Footer Form":
      classes += " " + styles.FooterForm;
      break;
    case "Feature List":
      classes += " " + styles.FeatureList;
      break;
    case "Image Vertical Top":
      classes += " " + styles.ImageVerticalTop;
      break;
  };
  return classes;
};

export const BannerCardContainer = (props) => (
  <div className={`${styles.container}` + getTemplateClasses(props.template) }>
    <BannerCardContent {...props} />
  </div>
);