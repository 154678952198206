import React from 'react';
import * as styles from './index.module.scss';

export const FooterBottom = ({ data }) => {
  return (
    <div className={styles.bottom}>
      <div className={styles.copyright}>
          <p>Ideal Heating {new Date().getFullYear()}. All Rights Reserved</p>
      </div>
      <ul className={styles.socials}>
        {data.map((social, index) => (
          <li key={`footer-socials-${index}`} className={styles.item}>
            <a href={social.url} alt={social.menuItemName} target="_blank" rel="noopener noreferrer">
              <img src={social.image.url} alt={social.menuItemName} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}